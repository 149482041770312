import { setCookie, removeCookie } from 'typescript-cookie';

import { FirebaseApp, FirebaseOptions, FirebaseAppSettings, initializeApp } from "firebase/app";
import { Auth, User, getAuth, onAuthStateChanged, signOut } from "firebase/auth";

import { Component, ComponentEventDataMap } from '@subgraph-io/edge/component';

import { API } from './api';
import { SiteHeaderNavComponent } from './components/SiteHeaderNavComponent';
import { SiteMobileNavComponent } from './components/SiteMobileNavComponent'
import { ErrorsComponent } from './components/ErrorsComponent';

export type SessionEventMap = ComponentEventDataMap & {
    authStateChanged: User | null
}

export class Session extends Component<SessionEventMap> {

    protected firebaseApp: FirebaseApp;
    protected firebaseAuth: Auth;
    protected api: API;

    protected errorsComponent: ErrorsComponent;
    protected siteHeaderNavComponent: SiteHeaderNavComponent;
    protected siteMobileNavComponent: SiteMobileNavComponent;

    protected loggedInUser: User | null;

    protected onUserPage: boolean;

    constructor(navigationData: any, userNavigationData: any, firebaseOptions: FirebaseOptions, onUserPage?: boolean, firebaseAppSettings?: FirebaseAppSettings) {
        super();
        this.firebaseApp = initializeApp(firebaseOptions, firebaseAppSettings);
        this.firebaseAuth = getAuth(this.firebaseApp);
        this.api = new API();
        this.loggedInUser = null;
        this.onUserPage = !!onUserPage;

        this.errorsComponent = new ErrorsComponent();
        document.body.append(this.errorsComponent.getDOMRoot());

        let headerNavRootElement, mobileNavRootElement;

        if (onUserPage) {
            headerNavRootElement = document.getElementById('header')?.parentElement as HTMLDivElement;
            mobileNavRootElement = document.getElementById('aside-nav')?.parentElement as HTMLDivElement;
        }
        else {
            headerNavRootElement = document.getElementById('header')?.parentElement as HTMLDivElement;
            mobileNavRootElement = document.getElementById('aside-nav')?.parentElement as HTMLDivElement;
        }

        this.siteHeaderNavComponent = new SiteHeaderNavComponent(navigationData, userNavigationData, headerNavRootElement, this.onUserPage);
        this.siteHeaderNavComponent.registerForEvent('CLASS_EVENT', 'onSignoutChosen', this.signOut, this);

        this.siteMobileNavComponent = new SiteMobileNavComponent(navigationData, userNavigationData, mobileNavRootElement, this.onUserPage);
        this.siteMobileNavComponent.registerForEvent('CLASS_EVENT', 'onSignoutChosen', this.signOut, this);

        onAuthStateChanged(this.firebaseAuth, async (user: User | null) => {
            if (user !== null) {
                this.loggedInUser = user;
                this.setCookie();
                this.renderLoggedIn();
                this.triggerEvent('CLASS_EVENT', 'authStateChanged', user);
            }
            else {
                this.loggedInUser = null;
                removeCookie('__session', {
                    domain: ".sixclear.com",
                    path: "/"
                });
                removeCookie('session_id', {
                    domain: ".sixclear.com",
                    path: "/"
                });
                this.renderLoggedOut();
                this.triggerEvent('CLASS_EVENT', 'authStateChanged', null);
            }
        });
    }

    public async setCookie() {
        if (this.loggedInUser) {
            const token = await this.loggedInUser.getIdToken(true);

            // this cookie is used by sixclear.com
            setCookie('__session', token, {
                domain: ".sixclear.com",
                // chrome recently changed behavior and only allows cookies to be set to expire 400 days in the future
                // https://developer.chrome.com/blog/cookie-max-age-expires/
                expires: new Date(Date.now() + (400 * 86400000)),
                path: "/"
            });

            // this cookie is used by portal
            setCookie('session_id', this.loggedInUser.uid, {
                domain: ".sixclear.com",
                // chrome recently changed behavior and only allows cookies to be set to expire 400 days in the future
                // https://developer.chrome.com/blog/cookie-max-age-expires/
                expires: new Date(Date.now() + (400 * 86400000)),
                path: "/"
            });

        }
    }

    public getFirebaseApp() {
        return this.firebaseApp;
    }

    public getFirebaseAuth() {
        return this.firebaseAuth;
    }

    public getAPI() {
        return this.api;
    }

    public getLoggedInUser() {
        return this.loggedInUser;
    }

    public addErrors(errors: { message: string }[]) {
        const existingErrors = this.errorsComponent.getTypedData('errors');

        this.errorsComponent.setTypedData('errors', existingErrors.concat(errors), 'replace');
    }

    public clearErrors() {
        this.errorsComponent.setTypedData('errors', [], 'replace');
    }

    public async signOut() {
        try {
            removeCookie('__session', {
                domain: ".sixclear.com",
                path: "/"
            });
            removeCookie('session_id', {
                domain: ".sixclear.com",
                path: "/"
            });
            await signOut(this.firebaseAuth);
            await this.sendPortalSignOut();
            window.location.href = '/views/auth/signin';
        } catch (error) {
            this.addErrors([{ message: 'An unknown error occurred and you could not be signed out. Please try again.' }])
        }
    }

    public async sendPortalSignOut() {
        // to sign out the portal session we need to hit an endpoint from the server,
        // so send api request to do it
        try {
            await this.api.signOut();
        }
        catch (error) {
            console.error(error);
        }
    }

    protected renderLoggedIn() {
        this.siteHeaderNavComponent.setTypedData('user', this.loggedInUser, 'replace');
        this.siteMobileNavComponent.setTypedData('user', this.loggedInUser, 'replace');
    }

    protected renderLoggedOut() {
        this.siteHeaderNavComponent.setTypedData('user', null, 'replace');
        this.siteMobileNavComponent.setTypedData('user', null, 'replace');
    }

}

export function init(...args: ConstructorParameters<typeof Session>): Session | void {
    try {
        return new Session(...args);
    } catch (error) {
        console.error(error);
    }
}